import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Box,
  Stack,
  Grid,
  Avatar,
  IconButton,
  TextField,
  Button,
} from "@mui/material";
import CheckoutForm from "./CheckoutForm";
import Header from "./Header";
import { useDispatch, useSelector } from "react-redux";
import ImageList from "@mui/material/ImageList";
// import ImageListItem from "@mui/material/ImageListItem";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import {
  updateQuantity,
  setTotalOrderCost,
  clearBasket,
} from "./redux-slices/BasketSlice";
import PaymentIcon from "@mui/icons-material/Payment";
import { ThemeProvider } from "@mui/material/styles";
import Footer from "./Footer";
import theme from "../theme";
import { useMediaQuery } from "@mui/material";
import { useAuth } from "./AuthenticationContext";
import { Helmet } from "react-helmet";

// const stripePromise = loadStripe("your-publishable-key-here");

function PurchasePage() {
  const { isAuthenticated, setIsAuthenticated } = useAuth();
  const dispatch = useDispatch();
  const [clientSecret, setClientSecret] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [quantity, setQuantity] = useState(0);
  const [checkout, setCheckout] = useState(false);

  const items = useSelector((state) => state.basket.items);
  const totalOrderCost = useSelector((state) => state.basket.orderTotalCost);
  const shippingTotal = useSelector((state) => state.basket.shippingTotal);
  const itemsTotalCost = useSelector((state) => state.basket.itemsTotalCost);

  const matchesXS = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  useEffect(() => {
    dispatch(setTotalOrderCost());
  }, [items]);

  // console.log("BREAKPOINT" + JSON.stringify(items, null, 2));

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        {/* Meta Tags for PurchasePage */}
        <Helmet>
          <title>Checkout | Okay Artist</title>
          <meta
            name="description"
            content="Complete your purchase with Okay Artist. Review your items and proceed to checkout."
          />
          <meta
            name="keywords"
            content="checkout, okay artist, custom products, shopping cart, online store"
          />
          <meta property="og:title" content="Checkout | Okay Artist" />
          <meta
            property="og:description"
            content="Ready to purchase? Review your items and proceed to secure checkout."
          />
          <meta
            property="og:image"
            content="https://product-images-okayartist.s3.amazonaws.com/generic-images/logo-for-meta-tags.jpg"
          />
          <meta
            property="og:url"
            content="https://www.okayartist.com/checkout"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="Checkout | Okay Artist" />
          <meta
            name="twitter:description"
            content="Complete your purchase with Okay Artist and enjoy custom products."
          />
          <meta
            name="twitter:image"
            content="https://product-images-okayartist.s3.amazonaws.com/generic-images/logo-for-meta-tags.jpg"
          />
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <Header />
        <Container
          disableGutters
          maxWidth
          sx={{ padding: "5%", display: "flex", justifyContent: "center" }}
        >
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Grid item xs={4} sm={4} md={6} sx={{ pr: "5%" }}>
              <Box>{checkout && !matchesXS ? <CheckoutForm /> : ""}</Box>
            </Grid>
            <Grid item xs={4} sm={4} md={6} sx={{ backgroundColor: "#D3D3D3" }}>
              {items.length === 0 ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <Typography variant="h6">Your basket is empty</Typography>
                </Box>
              ) : (
                <Stack direction={"column"} spacing={2}>
                  {items.map((item) => (
                    <ImageList cols={1} key={item.img}>
                      <Stack direction={"row"} spacing={2}>
                        <Box>
                          <Box
                            sx={{
                              width: 20, // Set the width of the circle
                              height: 20, // Set the height of the circle
                              borderRadius: "50%", // Make it a circle
                              backgroundColor: "white", // Example background color
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              position: "absolute",
                              zIndex: 1,
                            }}
                          >
                            <Typography variant="body">
                              {item.quantity}
                            </Typography>
                          </Box>
                          <Avatar
                            sx={{
                              width: "5rem",
                              height: "auto",
                              position: "relative",
                            }}
                            variant="square"
                            src={item.mockupImageURL}
                            alt={item.title}
                            loading="lazy"
                          />
                          {item.designURL && (
                            <Avatar
                              sx={{
                                width: "5rem",
                                height: "auto",
                                position: "relative",
                              }}
                              variant="square"
                              src={item.designURL}
                              alt={item.title}
                              loading="lazy"
                            />
                          )}
                        </Box>
                        <Box>
                          <Typography variant="subtitle2">
                            {item.listing.title}
                          </Typography>
                          <Typography variant="caption">
                            {item.color}/{item.size}
                          </Typography>
                          <Box>
                            <IconButton
                              onClick={() => {
                                if (
                                  !isNaN(item.quantity - 1) &&
                                  item.quantity - 1 > 0
                                )
                                  dispatch(
                                    updateQuantity({
                                      id: item.id,
                                      quantity: item.quantity - 1,
                                    })
                                  );
                              }}
                            >
                              <RemoveIcon fontSize="small" />
                            </IconButton>
                            <Typography variant="OVERLINE">
                              {item.quantity}
                            </Typography>
                            <IconButton
                              onClick={() => {
                                dispatch(
                                  updateQuantity({
                                    id: item.id,
                                    quantity: item.quantity + 1,
                                  })
                                );
                              }}
                            >
                              <AddIcon fontSize="small" />
                            </IconButton>
                          </Box>
                        </Box>
                        <Typography variant="subtitle1">
                          ${item.totalPrice}
                        </Typography>
                      </Stack>
                    </ImageList>
                  ))}
                  <Stack direction={"row"} spacing={1}>
                    <TextField
                      id="outlined-basic"
                      label="Discount Code"
                      variant="outlined"
                    />
                    <Button color="inherit" variant="outlined">
                      Apply
                    </Button>
                  </Stack>
                  <Button
                    onClick={() => {
                      dispatch(clearBasket());
                    }}
                    color="inherit"
                  >
                    Clear Basket
                  </Button>
                </Stack>
              )}
              {items.length > 0 && (
                <Box
                  style={{
                    textAlign: "right",
                    paddingRight: "10%",
                    paddingTop: "5%",
                  }}
                >
                  <Grid container spacing={{ xs: 1, md: 1 }} columns={2}>
                    <Grid item xs={1} sm={1} md={1}>
                      <Typography variant="overline">Subtotal</Typography>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1}>
                      <Typography variant="overline">
                        ${itemsTotalCost}
                      </Typography>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1}>
                      <Typography variant="overline">Shipping</Typography>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1}>
                      <Typography variant="overline">
                        ${shippingTotal}
                      </Typography>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1}>
                      <Typography variant="overline">
                        Estimated Taxes
                      </Typography>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1}>
                      <Typography variant="overline">${}0</Typography>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1}>
                      <Typography variant="h6">Total</Typography>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1}>
                      <Typography variant="h6">${totalOrderCost}</Typography>
                    </Grid>
                  </Grid>
                  {isAuthenticated && !checkout ? (
                    <Button
                      startIcon={<PaymentIcon />}
                      variant="contained"
                      color="primary"
                      sx={{ mt: 10, mb: 5 }}
                      onClick={() => {
                        setCheckout(true);
                      }}
                    >
                      Checkout
                    </Button>
                  ) : (
                    !checkout && (
                      <Typography
                        variant="h6"
                        style={{
                          color: "red",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {/* <WarningIcon style={{ marginRight: "8px" }} /> */}
                        Please login to place an order
                      </Typography>
                    )
                  )}
                </Box>
              )}
            </Grid>
            {checkout && matchesXS ? (
              <CheckoutForm totalOrderCost={totalOrderCost} />
            ) : (
              ""
            )}
          </Grid>
        </Container>
        <Footer />
      </Box>
    </ThemeProvider>
  );
}

export default PurchasePage;
