import React, { useContext, useEffect, useState, useRef } from "react";
import Button from "@mui/material/Button";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { useDispatch, useSelector } from "react-redux";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { unsetGridMenuClicked } from "../redux-slices/GridMenuClickedSlice";
import { CanvasContext } from "../CanvasContext";
import { fabric } from "fabric";
import {
  setActiveTemplate,
  setImageURL,
  setImgTemplateURL,
} from "../redux-slices/ImageSlice";

import checkerboardPattern from "../../assets/pattern2.jpg";
import { shirtDesign1, shirtDesign2 } from "./DesignTemplateFunctions";
// import DesignShirt1 from "./DesignShirt1";
// import DesignShirt2 from "./DesignShirt2";
import Box from "@mui/material/Box";

const itemData = [
  {
    img: "../../assets/menu-images/Shirt Templates 1.png",
    title: "Shirt Template 1",
  },
  {
    img: "../../assets/menu-images/Shirt Templates 2.png",
    title: "Shirt Template 2",
  },
];

const ShirtTemplate = () => {
  const dispatch = useDispatch();
  const imgTemplateURL = useSelector((state) => state.image.imgTemplateURL); // Access the image URL from the Redux stores
  const activeTemplate = useSelector((state) => state.image.activeTemplate); // Access the image URL from the Redux stores
  console.log("BREAKPOINT imgTemplateURL= " + imgTemplateURL);
  dispatch(setImageURL("")); //to remove the URL to avoid canvas conflict with template canvas and image processing canvas
  const {
    canvasRef,
    canvasRefTemplate,
    setCanvasRefTemplate,
    formats,
    font,
    setFont,
    textColor,
    setTextColor,
    fontSizing,
    setFontSizing,
  } = useContext(CanvasContext);
  // console.log("BREAKPOINT shirt template" + canvasRef.current);
  const [template, setTemplate] = useState("");
  const [shirtTemplate, setShirtTemplate] = useState(null);
  // var shirtTemplate;
  useEffect(() => {
    try {
      dispatch(setImageURL("fakeURL to trigger canvas2"));
      // function adjustCanvasSize(fabricCanvas) {
      //   const viewportWidth = window.innerWidth;
      //   const viewportHeight = window.innerHeight;

      //   // Calculate the scale factor
      //   const scaleX = viewportWidth / fabricCanvas.width;
      //   const scaleY = viewportHeight / fabricCanvas.height;
      //   const scaleToFit = Math.min(scaleX, scaleY);

      //   // Set canvas zoom
      //   fabricCanvas.setZoom(scaleToFit);

      //   // Update canvas element size to match the scaled drawing area
      //   fabricCanvas.setWidth(fabricCanvas.width * scaleToFit);
      //   fabricCanvas.setHeight(fabricCanvas.height * scaleToFit);

      //   // Center the canvas (optional)
      //   // const canvasContainer = fabricCanvas.getElement().parentNode;
      //   // canvasContainer.style.display = "flex";
      //   // canvasContainer.style.justifyContent = "center";
      //   // canvasContainer.style.alignItems = "center";
      // }
      // dispatch(setActiveTemplate(true));
      // const fabricCanvas = new fabric.Canvas(canvasRef.current, {
      //   width: 4500,
      //   height: 5400,
      //   // backgroundColor: "red",
      //   enableRetinaScaling: false,
      //   // width: imgWidth,
      //   // height: imgHeight,

      //   preserveObjectStacking: true,
      //   interactive: true, // Ensure interactivity is enabled
      //   backgroundColor: "rgba(119, 119, 119, 0.6)",
      //   stroke: "rgba(255,255,255,0.5)",
      //   strokeWidth: 5,
      //   shadow: "rgba(0,0,0,0.6) 5px 5px 5px",
      // });

      // // Adjust the canvas size to fit the viewport
      // adjustCanvasSize(fabricCanvas);
      // const zoom = fabricCanvas.getZoom();

      if (template === "Shirt Template 1") {
        //add first shirt design
        // setShirtTemplate(<DesignShirt1></DesignShirt1>);
        // shirtDesign1(fabricCanvas, zoom, imgTemplateURL);
      } else if (template === "Shirt Template 2") {
        // setShirtTemplate(<DesignShirt2></DesignShirt2>);
        //add first shirt design
        // shirtDesign2(fabricCanvas, zoom);
        // setShirtTemplate(<DesignShirt1></DesignShirt1>);
      }
      //store fabric canvas to state in context for do
      // setCanvasRefTemplate(fabricCanvas);
      return () => {
        dispatch(setActiveTemplate(false));
        // if (!activeTemplate) {
        //   setFont("anton");
        //   setFontSizing(500);
        // }

        // fabricCanvas.dispose();
        // setCanvasRefTemplate(null);
        // dispatch(setImgTemplateURL(""));
        // window.removeEventListener("resize", adjustCanvasSize(fabricCanvas));
      };
    } catch (error) {
      console.log("----ERROR inside ShirtTemplate: " + error);
    }
  }, [template]);

  return (
    <>
      <Button
        onClick={() => {
          dispatch(unsetGridMenuClicked());
          dispatch(setImgTemplateURL(""));
        }}
      >
        <ArrowBackIcon></ArrowBackIcon>
      </Button>
      <Box>{shirtTemplate}</Box>

      {/* <DesignShirt2></DesignShirt2> */}
      <ImageList cols={1}>
        {itemData.map((item) => (
          <ImageListItem key={item.img}>
            <Button>
              <img
                style={{ width: "100%", height: "auto" }}
                srcSet={`${item.img}`}
                src={require(`../../assets/menu-images/${item.title}.png`)}
                alt={item.title}
                loading="lazy"
                onClick={() => {
                  setTemplate(item.title);
                }}
              />
            </Button>
          </ImageListItem>
        ))}
      </ImageList>
    </>
  );
};
export default ShirtTemplate;
