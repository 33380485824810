import { fabric } from "fabric";
import imageBG from "../../assets/design-templates/retro-background.png";
import imageDesignBG from "../../assets/design-templates/retro-background.png";

function loadImage(url) {
  return new Promise((resolve, reject) => {
    fabric.Image.fromURL(
      url,
      (img) => {
        resolve(img);
      },
      {
        onError: (err) => reject(err),
      }
    );
  });
}

export const shirtDesign1 = (fabricCanvas, zoom, url) => {
  // Load the image from the URL
  fabric.Image.fromURL(imageBG, function (img) {
    // Optionally, set some options for the image
    img.set({
      left: (fabricCanvas.width / zoom - img.width * img.scaleX) / 2,
      top: 400,
      angle: 0,
      opacity: 1.0,
    });

    // Add the image to the canvas
    fabricCanvas.add(img);
    img.sendToBack();
    fabricCanvas.renderAll();
  });

  // Load the image from the URL
  fabric.Image.fromURL(url, function (img) {
    // Optionally, set some options for the image
    img.set({
      left: (fabricCanvas.width / zoom - img.width * img.scaleX) / 2,
      top: 400,
      angle: 0,
      opacity: 1.0,
    });

    // Add the image to the canvas
    fabricCanvas.add(img);
    img.sendToBack();
    fabricCanvas.renderAll();
  });

  //   const newCircle = new fabric.Circle({
  //     radius: 400, // Radius of the circle
  //     left: 400, // Position from the left on the canvas
  //     top: 100, // Position from the top on the canvas
  //     fill: "blue", // Fill color
  //     stroke: "green", // Border color
  //     strokeWidth: 4, // Border width
  //     selectable: true, // Keep the image selectable, or set to false to prevent selection
  //     hasControls: true, // Hide controls, prevent resizing
  //     hasBorders: true, // Hide borders
  //   });
  //   fabricCanvas.add(newCircle);

  const newText = new fabric.IText("Okay Artist", {
    // left: 2250, // Position from the left on the canvas
    // top: 100, // Position from the top on the canvas
    fontFamily: "Ayar Juno",
    fill: "white",
    fontSize: 500,
    selectable: true, // Keep the image selectable, or set to false to prevent selection
    hasControls: true, // Hide controls, prevent resizing
    hasBorders: true, // Hide borders
  });
  const centerX =
    (fabricCanvas.width / zoom - newText.width * newText.scaleX) / 2;
  const centerY =
    (fabricCanvas.height / zoom - newText.height * newText.scaleY) / 2;
  newText.set({ left: centerX, top: centerY });

  fabricCanvas.add(newText);
  //   fabricCanvas.bringForward(newText);

  // Alternatively, for more manual control especially if you need to consider specific scaling:

  fabricCanvas.renderAll(); // Render the canvas to display the new circle
  //   shirtDesign1(fabricCanvas, zoom, url);
};

export const shirtDesign2 = (fabricCanvas, zoom) => {
  const newCircle = new fabric.Circle({
    radius: 400, // Radius of the circle
    left: 400, // Position from the left on the canvas
    top: 100, // Position from the top on the canvas
    fill: "blue", // Fill color
    stroke: "green", // Border color
    strokeWidth: 4, // Border width
    selectable: true, // Keep the image selectable, or set to false to prevent selection
    hasControls: true, // Hide controls, prevent resizing
    hasBorders: true, // Hide borders
  });

  fabricCanvas.add(newCircle);
  // fabricCanvas.setZoom(0.05);
  const newText = new fabric.IText("Okay Artist 2", {
    // left: 2250, // Position from the left on the canvas
    // top: 100, // Position from the top on the canvas
    fontFamily: "Ayar Juno",
    fill: "white",
    fontSize: 500,
    selectable: true, // Keep the image selectable, or set to false to prevent selection
    hasControls: true, // Hide controls, prevent resizing
    hasBorders: true, // Hide borders
  });
  fabricCanvas.add(newText);
  // Alternatively, for more manual control especially if you need to consider specific scaling:

  const centerX =
    (fabricCanvas.width / zoom - newText.width * newText.scaleX) / 2;
  const centerY =
    (fabricCanvas.height / zoom - newText.height * newText.scaleY) / 2;
  newText.set({ left: centerX, top: centerY });

  fabricCanvas.renderAll(); // Render the canvas to display the new circle
};

export const designShirt1 = (fabricCanvas, zoom, url) => {
  Promise.all([loadImage(imageBG), loadImage(imageDesignBG)])
    .then(([imgProduct, imgDesign]) => {
      // Adjust image settings to cover the entire canvas
      //   const canvasAspect = fabricCanvas.width / fabricCanvas.height;
      //   const imgAspect = imgProduct.width / imgProduct.height;
      //   if (canvasAspect >= imgAspect) {
      //     imgProduct.scaleToWidth(fabricCanvas.width);
      //   } else {
      //     imgProduct.scaleToHeight(fabricCanvas.height);
      //   }
      // imgProduct.set({
      //   scaleX: 2.8,
      //   scaleY: 2.8,
      // });
      // imgProduct.set({
      //   left: 2250 - imgProduct.getScaledWidth() / 2,
      //   top: 2550 - imgProduct.getScaledHeight() / 2,
      //   // width: 4500,
      //   // height: 5400,
      //   angle: 0,
      //   opacity: 1.0,
      //   selectable: false,
      //   // evented: false,
      // });
      //   imgProduct.setZoom(globalZoom);
      // fabricCanvas.add(imgProduct);
      // fabricCanvas.sendToBack(imgProduct);
      fabricCanvas.add(imgDesign);
      imgDesign.set({
        scaleX: 0.7,
        scaleY: 0.7,
      });
      imgDesign.set({
        left: 2250 - imgDesign.getScaledWidth() / 2,
        top: 2550 - imgDesign.getScaledHeight() / 2,
        // width: 4500,
        // height: 5400,
        angle: 0,
        opacity: 1.0,
        selectable: false,
        // evented: false,
      });
    })
    .catch((error) => {
      console.error("Error loading images:", error);
    });

  const newText = new fabric.IText("Okay Artist", {
    fontFamily: "Ayar Juno",
    // fontFamily: font,
    fill: "white",
    fontSize: 500,
    // fontWeight: "bold",
    selectable: true,
    hasControls: true,
    hasBorders: true,
    stroke: "#000000", // Stroke color
    strokeWidth: 500 / 25, // Stroke width
  });

  // // Calculate the center position for the text
  // // Note: fabric.js may not immediately have width and height metrics for newText; consider setting initial left/top then adjusting
  // const centerX =
  //   (fabricCanvas.width / zoom - newText.width * newText.scaleX) / 2;
  // const centerY =
  //   (fabricCanvas.height / zoom - newText.height * newText.scaleY) / 2;
  // newText.set({ left: centerX, top: centerY });
  // // Add a renderAll call in the callback of the setText to ensure the canvas updates with correct width/height of newText
  // newText.on("changed", () => {
  //   const centerX =
  //     (fabricCanvas.width / zoom - newText.width * newText.scaleX) / 2;
  //   const centerY =
  //     (fabricCanvas.height / zoom - newText.height * newText.scaleY) / 2;
  //   newText.set({ left: centerX, top: centerY });
  //   fabricCanvas.renderAll();
  // });

  // // Now add the text object to the canvas
  fabricCanvas.add(newText);

  // // Bring the text to the front if needed
  // newText.bringToFront();
  // textObjectRef.current = newText;

  fabricCanvas.renderAll();
};
