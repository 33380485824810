import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, BrowserRouter as Router } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import HomePage from "./components/HomePage";
import AboutPage from "./components/AboutPage";
import Canvas from "./components/Canvas";
import LoginPage from "./components/LoginPage";
import SignupPage from "./components/SignupPage";
import ForgotPasswordDialog from "./components/ForgotPasswordDialog";

import { ThemeProvider } from "@emotion/react";
import { useTheme } from "@mui/material/styles";
import PasswordResetConfirm from "./components/PasswordResetConfirmation";
import ProductPage from "./components/ProductPage";
import PurchasePage from "./components/PurchasePage";
import PaymentSuccess from "./components/PaymentSuccess";
import ContactUsPage from "./components/ContactUsPage";
import UserProfile from "./components/UserProfile/UserProfile";
import PrivateRoute from "./components/PrivateRoute";
import ProductComponent from "./components/ProductComponent";
import CatalogPage from "./components/CatalogPage";
import ProductLandingComponent from "./components/ProductLandingComponent";
import CustomProductPage from "./components/CustomProductPage";
import CustomizeProductComponent from "./components/Design Templates/CustomizeProductComponent";
import CatalogListPage from "./components/CatalogListPage";
import OrderConfirmation from "./components/OrderConfirmation";
import RefundPolicy from "./components/RefundPolicy";
import ShippingPolicy from "./components/ShippingPolicy";
import ContactInformation from "./components/ContactInformation";
import TermsOfUse from "./components/TermsOfUsePage";
import PrivacyPolicy from "./components/PrivacyPolicy";
import { useLocation } from "react-router-dom";
import React, { lazy } from "react";

function App() {
  const theme = useTheme();

  const location = useLocation(); // Get the current route

  React.useEffect(() => {
    // Send a pageview to Google Analytics whenever the route changes
    window.gtag("config", "G-B0E71NSX3P", {
      page_path: location.pathname + location.search,
    });
  }, [location]); // Trigger useEffect whenever location (route) changes

  return (
    <ThemeProvider theme={theme}>
      <>
        {
          /* Your routes will go here */
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/product" element={<ProductPage />} />
            <Route path="/product-custom" element={<CustomProductPage />} />
            <Route path="/order-confirmation" element={<OrderConfirmation />} />

            <Route
              path="/products/:listingId/:designId"
              element={<ProductPage />}
            />

            <Route path="/catalog" element={<CatalogPage />} />
            <Route
              path="/catalog-list/:productType"
              element={<CatalogListPage />}
            />

            <Route path="/purchase" element={<PurchasePage />} />
            <Route path="/payment-success" element={<PaymentSuccess />} />

            <Route path="/about" element={<AboutPage />} />
            <Route path="/contact" element={<ContactUsPage />} />
            <Route path="/refund-policy" element={<RefundPolicy />} />
            <Route path="/shipping-policy" element={<ShippingPolicy />} />
            <Route path="/terms-of-use" element={<TermsOfUse />} />
            <Route
              path="/contact-information"
              element={<ContactInformation />}
            />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route
              path="/user-profile"
              element={
                <PrivateRoute>
                  <UserProfile />
                </PrivateRoute>
              }
            />
            <Route
              path="/product-template/:listingId/:productBrand/:designId"
              element={<CustomizeProductComponent />}
            />
            <Route path="/design" element={<Canvas />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/signup" element={<SignupPage />} />
            <Route path="/forgot-password" element={<ForgotPasswordDialog />} />
            <Route
              path="/reset-password/:uid/:token"
              element={<PasswordResetConfirm />}
            />
            {/* <Route path="/activate/:uid/:token" element={<HomePage />} /> */}
            {/* <Route path="/about" component={About} /> */}
          </Routes>
        }
      </>
    </ThemeProvider>
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
  );
}

export default App;
