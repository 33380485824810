import React, { lazy, Suspense } from "react";
import Header from "./Header";
import ProductComponent from "./ProductComponent";
import { ThemeProvider } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import theme from "../theme";
import Footer from "./Footer";
import { Box } from "@mui/material";
// import ProductLandingComponent from "./ProductLandingComponent";
const ProductLandingComponent = lazy(() => import("./ProductLandingComponent"));
const ProductPage = () => {
  // const theme = useTheme();

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Header />
        {/* <ProductComponent /> */}
        <Suspense fallback={<p></p>}>
          <ProductLandingComponent></ProductLandingComponent>
        </Suspense>
        <Footer></Footer>
      </Box>
    </ThemeProvider>
  );
};

export default ProductPage;
