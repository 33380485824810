// Skeleton for Slideshow
import { Skeleton, Box } from "@mui/material";

export function SlideshowSkeleton() {
  return (
    <Box sx={{ width: "100%", height: "60vh" }}>
      <Skeleton
        variant="rectangular"
        width="100%"
        height="100%"
        animation="wave"
      />
    </Box>
  );
}

// Skeleton for Featured Products
export function FeaturedProductsSkeleton() {
  return (
    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
      {[...Array(4)].map((_, index) => (
        <Box key={index} sx={{ width: 200 }}>
          <Skeleton variant="rectangular" width="100%" height={150} />
          <Skeleton width="60%" />
          <Skeleton width="80%" />
        </Box>
      ))}
    </Box>
  );
}
